<template>
  <div class="bg-main-bg wrap pb-20 flex justify-center">
    <div class="main-wrap flex flex-col items-center">
      <div
        class="header-wrap py-5 font-medium max-w-6x1 sm:flex items-center place-self-start"
      >
        <div class="text-secondary text-3x1 logo">
          <img src="/assets/images/LoanSpot-logo-alt.svg" />
        </div>
      </div>
      <div class="form-wrap mt-12">
        <div
          lass="sm:px-8 bg-white rounded-lg sm:pt-12 sm:pb-8 "
          class="max-w-xl bg-white sm:py-16 sm:px-20 sm:shadow-loan rounded-lg mx-auto sm:pt-12 sm:pb-8"
        >
          <h3 class="py-6 font-medium working--title sm:text-center">
            1. When working, I often set ambitious goals for myself.
          </h3>
          <form @submit.prevent="goTo" class="">
            <div class="radio-wrap">
              <label
                ref="strongAgreeLabel"
                for="s-agree"
                class="cursor-pointer text-title hover:text-primary h-12 bg-main-bg rounded-lg border border-border hover:border-primary pl-6 flex items-center text-sm mb-3"
              >
                Strongly Agree
                <svg
                  ref="strongAgreeIcon"
                  class="radio-img hidden"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                    fill="#2C479C"
                  />
                </svg>
                <input
                  @change="setBg()"
                  ref="strongAgree"
                  id="s-agree"
                  class="hidden"
                  type="radio"
                  name="working"
                />
              </label>
            </div>
            <div class="radio-wrap">
              <label
                ref="agreeLabel"
                for="agree"
                class="cursor-pointer text-title hover:text-primary h-12 bg-main-bg rounded-lg border border-border hover:border-primary pl-6 flex items-center text-sm mb-3"
              >
                Agree
                <svg
                  ref="agreeIcon"
                  class="radio-img hidden"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                    fill="#2C479C"
                  />
                </svg>
                <input
                  @change="setBg()"
                  ref="agree"
                  id="agree"
                  class="hidden"
                  type="radio"
                  name="working"
                />
              </label>
            </div>
            <div class="radio-wrap">
              <label
                ref="neutralLabel"
                for="neutral"
                class="cursor-pointer text-title hover:text-primary h-12 bg-main-bg rounded-lg border border-border hover:border-primary pl-6 flex items-center text-sm mb-3"
              >
                Neutral
                <svg
                  ref="neutralIcon"
                  class="radio-img hidden"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                    fill="#2C479C"
                  />
                </svg>
                <input
                  @change="setBg()"
                  ref="neutral"
                  id="neutral"
                  class="hidden"
                  type="radio"
                  name="working"
                />
              </label>
            </div>
            <div class="radio-wrap">
              <label
                ref="disagreeLabel"
                for="disagree"
                class="cursor-pointer text-title hover:text-primary h-12 bg-main-bg rounded-lg border border-border hover:border-primary pl-6 flex items-center text-sm mb-3"
              >
                Disagree
                <svg
                  ref="disagreeIcon"
                  class="radio-img hidden"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                    fill="#2C479C"
                  />
                </svg>
                <input
                  @change="setBg()"
                  ref="disagree"
                  id="disagree"
                  class="hidden"
                  type="radio"
                  name="working"
                />
              </label>
            </div>
            <div class="radio-wrap">
              <label
                ref="strongDisagreeLabel"
                for="s-disagree"
                class="cursor-pointer text-title hover:text-primary h-12 bg-main-bg rounded-lg border border-border hover:border-primary pl-6 flex items-center text-sm mb-8"
              >
                Strongly Disagree
                <svg
                  ref="strongDisagreeIcon"
                  class="radio-img hidden"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                    fill="#2C479C"
                  />
                </svg>
                <input
                  @change="setBg()"
                  ref="strongDisagree"
                  id="s-disagree"
                  class="hidden"
                  type="radio"
                  name="working"
                />
              </label>
            </div>
            <div class="flex">
              <Button>
                <span id="spin" slot="spin" class="animate-spin hidden">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                      fill="white"
                      fill-opacity="0.7"
                    />
                  </svg>
                </span>
                <span id="check" slot="check" class="hidden mr-1">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span id="next" slot="value"> Next </span>
              </Button>
            </div>
            <!-- <router-link
              to="/login"
              class="back--link flex sm:hidden px-4 bg-input-bg text-textColor border border-inputBorder2 rounded-md h-12 font-medium text-sm items-center justify-center"
            >
              Back
            </router-link> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/shared/button";
import { ONBOARDING_ROUTES } from "../../constants";
import { AUTH_ROUTES } from '../../constants';
import axios from 'axios';

export default {
  name: "Working",
  components: {
    Button,
  },
  props: [],
  data() {
    return {
      great: 'Great!',
      token: ''
    };
  },
  created() {
    this.getUser();
    this.getQuestions();
  },
  methods: {
    getUser() {
      const _token = localStorage.getItem('loanspotToken');
      if(!_token) {
        window.location.replace(AUTH_ROUTES.LOGIN)
      } else {
        this.token = `Bearer ${_token}`
        return false
      }
    },
    getQuestions() {
      axios.get('https://loanspot.africa/questions/questions', {
        'headers': {'Authorization': this.token}
      })
    },
    goTo(e) {
      e.preventDefault();
      document.getElementById("spin").classList.remove("hidden");
      document.getElementById("next").classList.add("hidden");
      setTimeout(() => {
        document.getElementById("spin").classList.add("hidden");
        document.getElementById("next").classList.remove("hidden");
        document.getElementById("next").innerText = this.great;
        document.getElementById("check").classList.remove("hidden");
        setTimeout(() => {
          window.location.assign(ONBOARDING_ROUTES.EXPERIENCE);
        }, 2000);
      }, 3000);
    },
    setBg() {
      const strongAgreeLabel = this.$refs.strongAgreeLabel;
      const agreeLabel = this.$refs.agreeLabel;
      const neutralLabel = this.$refs.neutralLabel;
      const disagreeLabel = this.$refs.disagreeLabel;
      const strongDisagreeLabel = this.$refs.strongDisagreeLabel;
      if (this.$refs.strongAgree.checked) {
        this.$refs.strongAgreeIcon.classList.remove("hidden");
        strongAgreeLabel.classList.add("bg-select-bg");
        strongAgreeLabel.classList.add("border-secondary");
        strongAgreeLabel.classList.remove("bg-radio-bg");

        this.$refs.agreeIcon.classList.add("hidden");
        agreeLabel.classList.remove("bg-select-bg");
        agreeLabel.classList.remove("border-secondary");
        agreeLabel.classList.add("bg-radio-bg");

        this.$refs.neutralIcon.classList.add("hidden");
        neutralLabel.classList.remove("bg-select-bg");
        neutralLabel.classList.remove("border-secondary");
        neutralLabel.classList.add("bg-radio-bg");

        this.$refs.disagreeIcon.classList.add("hidden");
        disagreeLabel.classList.remove("bg-select-bg");
        disagreeLabel.classList.remove("border-secondary");
        disagreeLabel.classList.add("bg-radio-bg");

        this.$refs.strongDisagreeIcon.classList.add("hidden");
        strongDisagreeLabel.classList.remove("bg-select-bg");
        strongDisagreeLabel.classList.remove("border-secondary");
        strongDisagreeLabel.classList.add("bg-radio-bg");
      } else if (this.$refs.agree.checked) {
        this.$refs.strongAgreeIcon.classList.add("hidden");
        strongAgreeLabel.classList.remove("bg-select-bg");
        strongAgreeLabel.classList.remove("border-secondary");
        strongAgreeLabel.classList.add("bg-radio-bg");

        this.$refs.agreeIcon.classList.remove("hidden");
        agreeLabel.classList.add("bg-select-bg");
        agreeLabel.classList.add("border-secondary");
        agreeLabel.classList.remove("bg-radio-bg");

        this.$refs.neutralIcon.classList.add("hidden");
        neutralLabel.classList.remove("bg-select-bg");
        neutralLabel.classList.remove("border-secondary");
        neutralLabel.classList.add("bg-radio-bg");

        this.$refs.disagreeIcon.classList.add("hidden");
        disagreeLabel.classList.remove("bg-select-bg");
        disagreeLabel.classList.remove("border-secondary");
        disagreeLabel.classList.add("bg-radio-bg");

        this.$refs.strongDisagreeIcon.classList.add("hidden");
        strongDisagreeLabel.classList.remove("bg-select-bg");
        strongDisagreeLabel.classList.remove("border-secondary");
        strongDisagreeLabel.classList.add("bg-radio-bg");
      } else if (this.$refs.neutral.checked) {
        this.$refs.strongAgreeIcon.classList.add("hidden");
        strongAgreeLabel.classList.remove("bg-select-bg");
        strongAgreeLabel.classList.remove("border-secondary");
        strongAgreeLabel.classList.add("bg-radio-bg");

        this.$refs.agreeIcon.classList.add("hidden");
        agreeLabel.classList.remove("bg-select-bg");
        agreeLabel.classList.remove("border-secondary");
        agreeLabel.classList.add("bg-radio-bg");

        this.$refs.neutralIcon.classList.remove("hidden");
        neutralLabel.classList.add("bg-select-bg");
        neutralLabel.classList.add("border-secondary");
        neutralLabel.classList.remove("bg-radio-bg");

        this.$refs.disagreeIcon.classList.add("hidden");
        disagreeLabel.classList.remove("bg-select-bg");
        disagreeLabel.classList.remove("border-secondary");
        disagreeLabel.classList.add("bg-radio-bg");

        this.$refs.strongDisagreeIcon.classList.add("hidden");
        strongDisagreeLabel.classList.remove("bg-select-bg");
        strongDisagreeLabel.classList.remove("border-secondary");
        strongDisagreeLabel.classList.add("bg-radio-bg");
      } else if (this.$refs.disagree.checked) {
        this.$refs.strongAgreeIcon.classList.add("hidden");
        strongAgreeLabel.classList.remove("bg-select-bg");
        strongAgreeLabel.classList.remove("border-secondary");
        strongAgreeLabel.classList.add("bg-radio-bg");

        this.$refs.agreeIcon.classList.add("hidden");
        agreeLabel.classList.remove("bg-select-bg");
        agreeLabel.classList.remove("border-secondary");
        agreeLabel.classList.add("bg-radio-bg");

        this.$refs.neutralIcon.classList.add("hidden");
        neutralLabel.classList.remove("bg-select-bg");
        neutralLabel.classList.remove("border-secondary");
        neutralLabel.classList.add("bg-radio-bg");

        this.$refs.disagreeIcon.classList.remove("hidden");
        disagreeLabel.classList.add("bg-select-bg");
        disagreeLabel.classList.add("border-secondary");
        disagreeLabel.classList.remove("bg-radio-bg");

        this.$refs.strongDisagreeIcon.classList.add("hidden");
        strongDisagreeLabel.classList.remove("bg-select-bg");
        strongDisagreeLabel.classList.remove("border-secondary");
        strongDisagreeLabel.classList.add("bg-radio-bg");
      } else if (this.$refs.strongDisagree.checked) {
        this.$refs.strongAgreeIcon.classList.add("hidden");
        strongAgreeLabel.classList.remove("bg-select-bg");
        strongAgreeLabel.classList.remove("border-secondary");
        strongAgreeLabel.classList.add("bg-radio-bg");

        this.$refs.agreeIcon.classList.add("hidden");
        agreeLabel.classList.remove("bg-select-bg");
        agreeLabel.classList.remove("border-secondary");
        agreeLabel.classList.add("bg-radio-bg");

        this.$refs.neutralIcon.classList.add("hidden");
        neutralLabel.classList.remove("bg-select-bg");
        neutralLabel.classList.remove("border-secondary");
        neutralLabel.classList.add("bg-radio-bg");

        this.$refs.disagreeIcon.classList.add("hidden");
        disagreeLabel.classList.remove("bg-select-bg");
        disagreeLabel.classList.remove("border-secondary");
        disagreeLabel.classList.add("bg-radio-bg");

        this.$refs.strongDisagreeIcon.classList.remove("hidden");
        strongDisagreeLabel.classList.add("bg-select-bg");
        strongDisagreeLabel.classList.add("border-secondary");
        strongDisagreeLabel.classList.remove("bg-radio-bg");
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0 10% 80px;
  min-height: max-content;
  @media (min-width: 640px) {
    
  }
}
.main-wrap {
  width: 100%;
  max-width: 1440px;
  @media (min-width: 1440px) {
    padding: 0 10%;
  }
}
.logo {
  font-family: EuclidCircularSemiB;
  font-size: 1.5rem;
}
.form-wrap {
  width: 100%;
  max-width: 550px;
  &::after {
    // display: none;
    @media (min-width: 640px) {
      content: "";
      display: block;
      height: 8px;
      width: 11%;
      background: #1656C1;
      border-radius: 0 0 0 8px;
      margin-top: -8px;
    }
  }
}
.working--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}
.radio-wrap {
  label {
    font-family: SohneLeicht, sans-serif;
    font-size: 0.875rem;
  }
}
.back--link {
  font-family: EuclidCircularReg;
}
</style>